
import { defineComponent } from 'vue';
import { useTable } from './walletInfo';
import Price from '@/components/modules/Price.vue';

export default defineComponent({
  components: { Price },
  setup() {
    return { ...useTable() };
  },
});
