
import { defineComponent, provide } from 'vue';
import { ManOutlined } from '@ant-design/icons-vue';
import { Statistic } from 'ant-design-vue';

import { useNpcInfo, useTable } from './setup';
import { useSkill } from '../components/skill-add/setup';
import upload from '@/components/modules/upload/index.vue';
import { DatePicker, TimePicker } from 'ant-design-vue';
import YImage from '@/components/modules/image/index.vue';
import skillAdd from '../components/skill-add/index.vue';
import Price from '@/components/modules/Price.vue';

// ARangePicker: DatePicker.RangePicker,
export default defineComponent({
  name: 'npcList',
  components: { upload, skillAdd, ATimePicker: TimePicker, ADatePicker: DatePicker, Price, YImage, ManOutlined, AStatistic: Statistic },
  setup() {
    const { skillShow, currentSkillInfo, handleAddSkill } = useSkill();

    provide('currentSkillInfo', currentSkillInfo);
    return {
      skillShow,
      handleAddSkill,
      ...useNpcInfo(),
      ...useTable(),
    };
  },
});
