
import { defineComponent, computed } from 'vue';
export default defineComponent({
  name: 'Price',
  props: {
    text: {
      type: [Number, String],
      default: '',
    },
    prefix: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'primary',
    },
  },
  setup(props) {
    const priceClass = computed(() => {
      return `ami_price_${props.type}`;
    });
    return { priceClass };
  },
});
