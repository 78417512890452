import { reactive, ref } from 'vue';
import _ from 'lodash';
import { skillTableModel, skillSearch } from '@/data/modules/npc';
import { skillList, skillDel, skillIsPlay,skillApplyReject,skillApplyPassed } from '@/api/npc';
import { awaitWrap } from '@/util/index';
import { ModalError ,ModalReason} from '@/util/message';
import { message } from 'ant-design-vue';
import { useInitRoute } from '@/util/router';
export function useTable(): any {
  const tableColumns = skillTableModel;

  const { route } = useInitRoute();

  const searchRef = reactive(_.clone(skillSearch));
  searchRef.NickName = route.query.NickName || '';

  const pagination = reactive({
    current: 1,
    total: 10,
  });

  const tableSource = ref([]);
  const tableLoading = ref(false);

  async function getList() {
    tableLoading.value = true;
    const { code, data } = await awaitWrap(skillList(searchRef));
    tableLoading.value = false;
    if (code === 200) {
      tableSource.value = data.data.DataList;
      pagination.total = data.data.RecordCount;
    }
  }
  getList();

  function handlePageChange(val: any) {
    pagination.current = val;
    searchRef.PageIndex = val;
    getList();
  }

  function handleReset() {
    _.assignIn(searchRef, skillSearch);
  }

  function handleDel(id: number) {
    ModalError('你确定要删除', async () => {
      const { code } = await awaitWrap(skillDel(id));
      return new Promise((resolve, reject) => {
        if (code === 200) {
          message.success('删除成功');
          getList();
          resolve('');
        } else {
          reject();
        }
      });
    });
  }

  function handleIsPlay(e: any, val: any) {
    ModalError('你确定要修改玩家技能', async () => {
      const { code } = await awaitWrap(skillIsPlay(val.Id, e ? 1 : 0));
      return new Promise((resolve, reject) => {
        if (code === 200) {
          message.success('修改成功');
          val.CanPlay = e;
          resolve('');
        } else {
          reject();
        }
      });
    });
  }

  //审核通过
  async function handleApplyPassed(id: number) {
    await skillApplyPassed(id);
    message.success('成功');
    getList();
  }

  //审核拒绝
  function handleApplyRefuse(id: number) {
    ModalReason('填写拒绝理由', async function (val: any) {
      await skillApplyReject(id, val);
      message.success('成功');
      getList();
    });
  }

  return {
    tableColumns,
    searchRef,
    pagination,
    tableSource,
    tableLoading,
    getList,
    handlePageChange,
    handleReset,
    handleDel,
    handleIsPlay,
    handleApplyRefuse,
    handleApplyPassed
  };
}
