import { reactive, createVNode, computed, ref, getCurrentInstance } from 'vue';
import _ from 'lodash';
import { categoryInfoModel, categoryTableModel } from '@/data/modules/npc';
import { addGameCategory, delGameCategory, gameCategoryList } from '@/api/category';
import { awaitWrap } from '@/util/index';
import { message, Modal } from 'ant-design-vue';
import { ExclamationCircleOutlined } from '@ant-design/icons-vue';
//标题use
export function useCategoryTable(): any {
  const tableColumns = categoryTableModel;
  const tableLoading = ref(false);
  const tableSource = ref([]);
  const expandedRowKeys: any = ref([]);

  //获取分类信息

  async function getCategoryList() {
    tableLoading.value = true;
    const { code, data } = await awaitWrap(gameCategoryList());

    //保存本地缓存
   sessionStorage.setItem('category',JSON.stringify(data.data))

    tableLoading.value = false;
    if (code === 200) {
      tableSource.value = data.data.map((item: any) => {
        expandedRowKeys.value.push(item.Id);
        if (item.Child.length) {
          item.Child.map((el: any) => {
            el.Child = '';
          });
        } else {
          item.Child = '';
        }
        return item;
      });
    }
  }

  getCategoryList();

  // 删除分类
  function delCategory(id: number) {
    Modal.confirm({
      title: '删除确认',
      icon: createVNode(ExclamationCircleOutlined),
      content: '你确定要删除?',
      okType: 'danger',
      async onOk() {
        const { code } = await awaitWrap(delGameCategory(id));
        return new Promise((resolve, reject) => {
          if (code === 200) {
            message.success('删除成功');
            getCategoryList();
            resolve('');
          } else {
            reject();
          }
        });
      },
    });
  }

  return {
    expandedRowKeys,
    getCategoryList,
    delCategory,
    tableColumns,
    tableSource,
  };
}

export function useCategoryInfo(): any {
  const { proxy }: any = getCurrentInstance();
  const formRef = ref();

  //弹出层控制
  const showModel = ref(false);

  //验证规则
  const roleAddCategory = {
    CategoryName: [{ required: true, message: '请输入分类名称', trigger: 'change' }],
  };

  //添加npc信息
  const categoryInfo = reactive(_.clone(categoryInfoModel));

  const imageComputed = computed({
    get() {
      return categoryInfo.Icon ? categoryInfo.Icon.split('|') : [];
    },
    set(val: Array<string>) {
      categoryInfo.Icon = val.join('|');
    },
  });

  //调用方法
  function openShowModel(): void {
    _.assignIn(categoryInfo, categoryInfoModel);
    showModel.value = true;
  }

  //添加下级
  function handleAddChild(ParentId: number): void {
    _.assignIn(categoryInfo, categoryInfoModel, { ParentId: ParentId });
    showModel.value = true;
  }

  //编辑信息
  function handleEdit(el: any) {
    _.assignIn(categoryInfo, el);
    showModel.value = true;
  }

  async function haeldAddCategory(callback: () => void) {
    const validate = await awaitWrap(formRef.value.validate());
    if (validate.code === 200) {
      const ret = await awaitWrap(addGameCategory(categoryInfo));
      if (ret.code === 200) {
        message.success('编辑成功');
        showModel.value = false;
        sessionStorage.removeItem('category')
        proxy.getCategoryList();
      }
    }
    callback();
  }

  return { categoryInfo, showModel, roleAddCategory, formRef, imageComputed, openShowModel, handleEdit, handleAddChild, haeldAddCategory };
}
