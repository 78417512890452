
import { defineComponent, provide } from 'vue';
import { useTable } from './setup';
import YImage from '@/components/modules/image/index.vue';
import skillAdd from '../components/skill-add/index.vue';
import { useSkill } from '../components/skill-add/setup';
import { useCategoryTable } from '../category/setup';

import Price from '@/components/modules/Price.vue';

export default defineComponent({
  name: 'npcSkill',
  components: { YImage, skillAdd, Price },
  setup() {
    const { skillShow, currentSkillInfo, handleEditSkill } = useSkill();
    const { tableSource: categorylist } = useCategoryTable();
    provide('currentSkillInfo', currentSkillInfo);
    return {
      categorylist,
      handleEditSkill,
      skillShow,
      ...useTable(),
    };
  },
});
