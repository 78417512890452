import request from './axios';

interface gameInfo {
  "CategoryName":string;
  "Icon": string;
  "Id": number;
  "ParentId": number;
  "Sort": number
}

// 添加游戏分类
export const addGameCategory = (data: gameInfo) => {
  return request({
    url: '/game/category/add',
    method: 'post',
    data: data
  });
};


// 删除游戏分类
export const delGameCategory = (id: number) => {
  return request({
    url: `/game/category/${id}/del`,
    method: 'delete',
  });
};

// 获取游戏分类列表
export const gameCategoryList = () => {

  const  categoryList = JSON.parse(sessionStorage.getItem('category') || '[]')
  if(categoryList.length){
    return Promise.resolve({
      code:200,
      data:categoryList
    })
  }
  return request({
    url: `/game/category/list`,
    method: 'get',
  });
};


