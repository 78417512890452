
import { defineComponent, computed, ref, inject } from 'vue';
import _ from 'lodash';
import upload from '@/components/modules/upload/index.vue';
import { skillInfoModel } from '@/data/modules/npc';
import { useCategoryTable } from '../../category/setup';
import { addSkill } from '@/api/npc';
import { awaitWrap } from '@/util/index';
import { message } from 'ant-design-vue';
export default defineComponent({
  components: { upload },
  name: 'skillAdd',
  props: {
    // skillInfo: {
    //   type: Object,
    //   default() {
    //     return skillInfoModel;
    //   },
    // },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:visible', 'handld-save'],
  setup(props: any, { emit }) {
    const skillInfo = inject('currentSkillInfo', _.clone(skillInfoModel));

    const { tableSource: categorylist } = useCategoryTable();

    const show = computed({
      get() {
        return props.visible;
      },
      set(val) {
        emit('update:visible', val);
      },
    });

    const formRef = ref();

    const infoRole = {
      SecondCategory: [{ type: 'number', required: true, message: '请选择分类', trigger: 'change' }],
      Banner: [{ required: true, message: '请上传轮播图', trigger: 'change' }],
      SkillTitle: [{ required: true, message: '请输入标题', trigger: 'change' }],
      Description: [{ required: true, message: '请输入描述', trigger: 'change' }],
      ServiceExplain: [{ required: true, message: '请输入服务说明', trigger: 'change' }],
      UnitPrice: [{ type: 'number', required: true, message: '请输入单价', trigger: 'change' }],
      RenewUnitPrice: [{ type: 'number', required: true, message: '请输入续费单价', trigger: 'change' }],
    };

    const imageComputed = computed({
      get() {
        return skillInfo.Banner ? skillInfo.Banner.split('|') : [];
      },
      set(val: Array<string>) {
        formRef.value.clearValidate('Banner');
        skillInfo.Banner = val.join('|');
      },
    });

    async function haeldAdd(callback: () => void) {
      const validate = await awaitWrap(formRef.value.validate());
      if (validate.code === 200) {
        const ret = await awaitWrap(addSkill(skillInfo));
        if (ret.code === 200) {
          message.success('添加成功');
          emit('handld-save', {});
          show.value = false;
        }
      }
      callback();
    }

    return { categorylist, skillInfo, show, infoRole, formRef, imageComputed, haeldAdd };
  },
});
