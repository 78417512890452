import { ref, reactive } from 'vue';
import { skillInfoModel } from '@/data/modules/npc';
import { skillDetail } from '@/api/npc';
import { awaitWrap } from '@/util/index';

import _ from 'lodash';
export function useSkill() {
  const skillShow = ref(false);

  const currentSkillInfo = reactive(_.clone(skillInfoModel));

  function handleAddSkill(el: any) {
    _.assignIn(currentSkillInfo, skillInfoModel, el);
    skillShow.value = true;
  }

  async function handleEditSkill(el: { Id: number; NickName: string }) {
    const { code, data } = await awaitWrap(skillDetail(el.Id));
    if (code === 200) {
      _.assignIn(currentSkillInfo, data.data, { NpcName: el.NickName });
      skillShow.value = true;
    }
  }
  // provide('currentSkillInfo', currentSkillInfo)
  return { skillShow, handleAddSkill, currentSkillInfo, handleEditSkill };
}
