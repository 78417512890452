
import { defineComponent } from 'vue';
// import { Card } from 'ant-design-vue';
// import upload from '@/components/modules/upload/index.vue';
import { useCategoryInfo, useCategoryTable } from './setup';
import upload from '@/components/modules/upload/index.vue';
import YImage from '@/components/modules/image/index.vue';

export default defineComponent({
  // components: { ACard: Card, upload },
  components: { upload, YImage },
  setup() {
    return {
      ...useCategoryInfo(),
      ...useCategoryTable(),
    };
  },
});
