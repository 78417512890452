import { reactive, ref } from 'vue';
import _ from 'lodash';
import { npcWalletTableModel, npcWalletSearchModel, npcWalletDetailStatus, npcWalletAmountType } from '@/data/modules/npc/index';
import { walletInfo } from '@/api/npc';
import { awaitWrap } from '@/util/index';
import { useInitRoute } from '@/util/router';
//表单hooks
export function useTable(): any {
  const { queryParams } = useInitRoute();
  //搜索数据
  const searchRef = reactive(_.clone(npcWalletSearchModel));

  //重置表单
  function handleReset() {
    _.assignIn(searchRef, npcWalletSearchModel);
  }

  //分页数据
  const pagination = reactive({
    current: 1,
    total: 10,
  });

  //表格数据
  const tableColumns = npcWalletTableModel;
  const tableSource = ref([]);
  const tableLoading = ref(false);

  searchRef.NpcId = queryParams('id');
  // 获取列表
  async function getList() {
    tableLoading.value = true;
    const { code, data } = await awaitWrap(walletInfo(searchRef));
    tableLoading.value = false;
    if (code === 200) {
      tableSource.value = data.data.DataList;
      pagination.total = data.data.RecordCount;
    }
  }

  //分页操作
  function handlePageChange(val: any) {
    pagination.current = val;
    searchRef.PageIndex = val;
    getList();
  }

  getList();

  return {
    npcWalletDetailStatus,
    npcWalletAmountType,
    tableLoading,
    tableColumns,
    tableSource,
    pagination,
    searchRef,
    handlePageChange,
    getList,
    handleReset,
  };
}
